import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const Disruption = lazy(() => import("./features/disruption"));

export default ({ childProps }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/disruption" component={Disruption} />
        <Route path="*">Not Found</Route>
      </Switch>
    </Suspense>
  );
};
