import React from 'react';

import './style/index.scss';
import Routes from "../Routes";

function App() {
  return (
      <div className="App">
        <Routes />
      </div>
  );
}

export default App;
